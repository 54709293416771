import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


// import Index from './views';
import Youtube from './views/youtube';
import TikTok from './views/tiktok';
import PrivacyPolicy from './views/privacy-policy';
import TermsofService from './views/terms-of-service';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
  <Routes>
    {/* <Route path="/" element={<Index />} /> */}
    <Route path="/" element={<Navigate to="/youtube" />} />
    <Route path="/youtube" element={<Youtube />} />
    <Route path="/tiktok" element={<TikTok />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/terms-of-service" element={<TermsofService />} />
  </Routes>
</BrowserRouter>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
