import React, {useState, useEffect} from 'react';
import MainMenu from '../components/nav';
import '../assets/App.css';
import { Container, Input, Button, Row, Col, Accordion, AccordionItem, AccordionHeader, AccordionBody, List, Spinner } from 'reactstrap';
import Footer from '../components/footer';
// import Favorite from '../components/favorite';
// import { useNavigate } from 'react-router-dom';
import DownDetails from '../components/downdetails';
import { API_BASE_URL } from '../config';

function Youtube() {

  // let navgate = useNavigate();


  const [value, setValue] = useState(""); 
  // const [detials, setDownDetails] = useState({}); 
  const [loading, setLoading] = useState(false);
  const [loadingstyle, setLoadingStyle] = useState({});
  const [error, setError] = useState(null);
  const [displaydownload, setDisplayDownload] = useState('none');
  const [videos, setVideos] = useState([]);
  const [video_title, setVideoTitle] = useState('');
  const [video_thumb, setVideoThumb] = useState('');

  useEffect( () => {
    if (loading) {
      setLoadingStyle({marginTop:80, display: 'inline'})
    }
    else
    {
      setLoadingStyle({marginTop:80, display: 'none'})
    }
  }, [loading])

  const [open, setOpen] = useState('1');
  const toggle = (id:string) => {
    if (open === id) {
      setOpen('1');
    } else {
      setOpen(id);
    }
  };

  const submit = (url: string) => {
    setLoading(true);
    setDisplayDownload("none");

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      
      body: JSON.stringify({ video_url: url })
    };

    console.log(`${API_BASE_URL}youtube`)

    fetch(`${API_BASE_URL}youtube`, requestOptions)
    // fetch(API_BASE_URL + "youtube", requestOptions)
    .then(response => {
      if (response.ok) return response.json();
      throw response;
    })
    .then(json => {
      console.log(json)
      setVideos(json['videos'])
      setVideoTitle(json['title'])
      setVideoThumb(json['thumbnail_url'])
      setDisplayDownload("inline");
    })
    .catch(err => {
      setError(err);
      console.log(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      <MainMenu/>
      <div className="App">
        <section className='section_top'>
          <Container>
            {/* <Form> */}
            <Row>
              <Col>
              <h1>YouTube Video Downloader</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                 <br/>
              </Col>
            </Row>
            <Row className='align-items-center'>
              <Col 
                md={{
                  offset: 2,
                  size: 7
                }}
                sm="12"
              >
                <Input type="url" bsSize='lg' onChange={(e) => setValue(e.target.value)}/>
              </Col>
              <Col sm="1"
              >
                <Button
                  size='lg'
                  onClick={() => submit(value)}
                >
                  Download
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                 <br/>
              </Col>
            </Row>
            <Row>
              <Col>Download youtube video for free, 8k, 4k, 1080p, 720p and mp3</Col>
            </Row>
            {/* </Form> */}
          </Container>
          <Spinner style={loadingstyle}>Loading...</Spinner>
          <DownDetails display={displaydownload} videos={videos} title={video_title} thumb={video_thumb}/>
        </section>
        <section className='section_faq'>
          <Container style={{marginTop:40, marginBottom:40, textAlign:'center'}}>
            <h3>FAQ</h3>
          <Accordion flush open={open} toggle={toggle} style={{width:'80%', margin:'auto', textAlign:'left'}}>
            <AccordionItem>
              <AccordionHeader targetId="1"><h5>How to download Youtube videos? (Quick Guide)</h5></AccordionHeader>
              <AccordionBody accordionId="1">
                <List>
                  <li>Open the Youtube web site on your phone or on the Web in your browser.</li>
                  <li>Choose whatever videos or images you want to download. Then copy the sharing link.</li>
                  <li>Go back and paste your share link in the field above, then click the Download button.</li>
                  <li>Wait for our server to do its job, and then save the media file to your device.</li>
                </List>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="2"><h5>Where are videos or images saved after being downloaded?</h5></AccordionHeader>
              <AccordionBody accordionId="2">
              When you're downloading files, they are usually saved into whatever folder you have set as your default. Your browser normally sets this folder for you. In browser settings, you can change and choose manually the destination folder for your downloaded media files.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="3"><h5>How do I save videos to my iPhone (iOS)?</h5></AccordionHeader>
              <AccordionBody accordionId="3">
              Because of Apple's privacy policy, from iOS 12 and iPadOS 12 and below, you cannot download any videos, music, or movies to your iPhone. You should update to the latest iOS version to be able to use Safari to save videos. If you still can't download, please follow the tutorial.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="4"><h5>Do I need to install instructions or extensions?</h5></AccordionHeader>
              <AccordionBody accordionId="4">
              No. I try to keep things easy for our users. All you need are your share links. That's it.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          </Container>
        </section>
        
        {/* <section className="section_dark">
          <Favorite/>
        </section> */}
      </div>
      <Footer/>
    </>
  );
}

export default Youtube;
