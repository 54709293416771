import React from 'react';
import MainMenu from '../components/nav';
import '../assets/App.css';
import { Container } from 'reactstrap';
import Footer from '../components/footer';

function TermsofService() {
  return (
    <>
      <MainMenu/>
      <div className="App">
        <section className='App-article'>
          <h1>Terms of Service</h1>
          <Container>
          <div>
            <p>Your continued use of this website constitutes your acceptance of these terms of service.</p>
            <h3>Terms of using Fetchtoo</h3>
            <ul>
              <li>This website is intended for personal use only.</li>
              <li>You may use our website as a recording service only and only as permitted by law.</li>
              <li>You may not use our services to download or convert any copyrighted material.</li>
              <li>Your are solely responsible for any misuse of these services and any copyright violations that may occur by using our website.</li>
              <li>You are responsible for reading the terms of service of the websites you are downloading any media from, as you might be breaking their terms of service by using our services.</li>
              <li>We are not to be held responsible for any misuse of the services provided on this website and any damages that might occur by using these services.</li>
              <li>We reserve the right to amend these terms at any time.</li>
              <li>Your continued use of this website constitutes your acceptance of these terms of service.</li>
            </ul>
          </div>
          </Container>
        </section>
      </div>
      <Footer/>
    </>
  );
}

export default TermsofService;