import {
  Container, Row, Col, List
} from 'reactstrap';

import '../assets/App.css';

function Footer() {
  return (
    <footer className='App-footer'>
      <Container style={{marginTop:40, marginBottom:40}}>
        <Row>
          <Col>
          <List type="unstyled">
            <li><h5>TOOLS</h5></li>
            <li>
              <a href='/youtube' title='download youtube video for free'>Youtube Video Downloader</a>
            </li>
            <li>
              <a href='/tiktok' title='download tiktok video for free'>TikTok Video Downloader</a>
            </li>
            {/* <li>
              <a href='/instagram'>Instagram Video Downloader</a>
            </li>
            <li>
              <a href='/pinterest'>Pinterest Video Downloader</a>
            </li> */}
          </List>
          </Col>
          <Col>
          {/* <List type="unstyled">
            <li><h5>LINKS</h5></li>
            <li>
              <a href='/twitter'>Twitter Video Downloader</a>
            </li>
            <li>
              <a href='/snapchat'>Snapchat Video Downloader</a>
            </li>
            <li>
              <a href='/xvideos'>Xvideos Video Downloader</a>
            </li>
            <li>
              <a href='/pronhub'>PronHub Video Downloader</a>
            </li>
          </List> */}
          </Col>
          <Col>
          <List type="unstyled">
            <li>
 
            </li>
          </List>
          </Col>
          <Col>
          <List type="unstyled">
            <li><h5>ABOUT</h5></li>
            <li>
              <a href='/privacy-policy'>Privacy Policy</a>
            </li>
            <li>
              <a href='/terms-of-service'>Terms of Service</a>
            </li>
          </List>
          </Col>
        </Row>
      </Container>
      <Container
          className="App-copyright"
          fluid
      >
      Copyright © 2024 All Rights Reseved.
      </Container>
    </footer>
  );
}

export default Footer;