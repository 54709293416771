import {
  Container, Row, Col, Table, Button, CardBody, CardText, Card, CardImg
} from 'reactstrap';
import '../assets/App.css';

function DownDetails(props: any) {

    const download = (url:string) => {
        window.open(url, '_blank');
    }

    
    return (
        <Container 
            style={{marginTop:80, display:props.display}}
        >
            <Row>
                <Col>
                    <Card className="my-2">
                        <CardImg
                            alt={props.title}
                            src={props.thumb}
                            style={{
                                // height: 180
                            }}
                            top
                            width="100%"
                        />
                        <CardBody>
                            <CardText>
                            {props.title}
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Table bordered >
                        <thead>
                            <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Type
                            </th>
                            <th>
                                Quality
                            </th>
                            <th>
                                Download
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            typeof props.videos === "undefined" ? "" : props.videos.map((data:any, index:number) => (
                                <tr>
                                <th scope="row">
                                    {index}
                                </th>
                                <td>
                                    {data.subtype}
                                </td>
                                <td>
                                    {data.resolution}
                                </td>
                                <td>
                                    <Button size='sm' onClick={() => download(data.url)}>Download</Button>
                                </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default DownDetails;