import React from 'react';
import MainMenu from '../components/nav';
import '../assets/App.css';
import { Container} from 'reactstrap';
import Footer from '../components/footer';

function PrivacyPolicy() {
  return (
    <>
      <MainMenu/>
      <div className="App">
        <section className='App-article'>
        <h1>Privacy Policy</h1>
          <Container>
            <div>
              <h3>Summary</h3>
              <p>You may use <strong>Fetchtoo</strong> anonymously and without providing any personal information at all, but like most websites we collect non personally identifying information that are made available by web browsers and web servers and this information is only used to better understand how visitors use our website.</p><p>We also collect potentially personally identifying information like your IP address (the Internet address of your computer) for the purpose of limiting the usage of our services to prevent abuse and keep our services available to everyone.</p>
              <p>We don't store any personal information on our servers unless required for the operation of our services and it's usually for a limited time only, we also don't share your personal information with anyone.</p>
              <h3>Automatically Collected Information</h3>
              <p>Some of the information that is collected on <strong>Fetchtoo</strong> and used by the third party services that we use:</p>
              <ul>
                <li>IP address</li>
                <li>Browser type</li>
                <li>Date and time of each request</li>
                <li>Referring site</li>
                <li>Language preference</li>
              </ul>
              <p>This information is stored temporarly in log files on our servers for a short period of time for the purpose of providing our services.</p>
              <h3>Cookies</h3>
              <p>Cookies are text files stored on your computer, we use cookies which help us provide you with a good experience, improve our service, and personalize ads and content that may be delivered to you while you use the website, we also use third party services that make use of cookies to collect traffic data and provide advertising services.</p>
              <p>We collect anonymous usage information on the website visitors through the use of Google Analytics which may use tracking cookies to gather anonymous browser, operating system, geographic, and web site navigation information, to opt out of Google Analytics <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="nofollow noreferrer noopener">Install Google Analytics Opt-out Browser Add-on</a>.</p>
              <h3>Links</h3>
              <p>This website may contain links to other sites, please be aware that we are not responsible for the contents or privacy of such websites, you are encouraged to read the privacy policies of any other site that collects personally identifiable information.</p><h3>Privacy Policy Changes</h3><p><strong>Fetchtoo</strong> may change its privacy policy when necessary, a notification will be posted clearly on the homepage for any major changes, the date of the last update will be shown on the top of the page. Your continued use of this site after any change in this privacy policy will constitute your acceptance of such change.</p>
            </div>
          </Container>
        </section>
      </div>
      <Footer/>
    </>
  );
}

export default PrivacyPolicy;