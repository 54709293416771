import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from 'reactstrap';

function MainMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar dark color='dark' full fixed='top' expand container>
        <NavbarBrand href="/">Fetchtoo</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink href="/youtube/">Youtube</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/tiktok/">Tiktok</NavLink>
            </NavItem>
{/*             
            <NavItem>
              <NavLink href="/instagram/">Instagram</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/twitter/">Twitter</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/pinterest/">Pinterest</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/snapchat/">Snapchat</NavLink>
            </NavItem> */}
          </Nav>
          <NavbarText> </NavbarText>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default MainMenu;